<template>
  <v-container
    fluid
    grid-list-xl
    class="funds"
  >
    <toolbar
      :title="$t('bonds.bonds')"
      :search.sync="search"
      :search-label="$t('funds.searchLabel')"
      :loading="loading"
      @search="getBond(search)"
    >
      <template v-slot:preSearch>
        <v-divider
          vertical
          class="mr-4 ml-2"
        ></v-divider>
      </template>
      <template v-slot:actions>
        <v-col class="shrink">
          <v-btn
            v-if="activeBond"
            large
            color="primary"
            @click="saveBond"
          >
            <v-icon
              left
            >
              save
            </v-icon>
            {{ $t('actions.save') }}
          </v-btn>
        </v-col>
      </template>
    </toolbar>

    <v-row
      v-if="activeBond"
      row
      wrap
    >
      <v-col class="shrink ">
        <v-card>
          <v-card-title>
            {{ $t('bonds.startSubscriptionDate') }}
          </v-card-title>
          <v-card-text>
            <v-date-picker
              :value="activeBond.subscriptionInfo.start.substr(0,10)"
              :locale="$vuetify.lang.current"
              @input="input => setDate('start', input)"
            ></v-date-picker>
            <v-text-field
              :value="activeBond.subscriptionInfo.start.substr(0,10)"
              @change="input => setDate('start', input)"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="shrink ">
        <v-card>
          <v-card-title>
            {{ $t('bonds.endSubscriptionDate') }}
          </v-card-title>
          <v-card-text>
            <v-date-picker
              :value="activeBond.subscriptionInfo.end.substr(0,10)"
              :locale="$vuetify.lang.current"
              @input="input => setDate('end', input)"
            ></v-date-picker>
            <v-text-field
              :value="activeBond.subscriptionInfo.end.substr(0,10)"
              @change="input => setDate('end', input)"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="shrink">
        <v-card
          min-width="200"
        >
          <v-card-text>
            <v-text-field
              v-model="activeBond.primaryInfo.price"
              :label="$t('bonds.price')"
              type="number"
              outlined
            ></v-text-field>
            <v-text-field
              v-model="activeBond.subscriptionInfo.issueSurcharge"
              :label="$t('bonds.issueSurcharge')"
              type="number"
              outlined
              hide-details
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="activeBond">
      <v-col
        v-for="documentType in documentTypes"
        :key="documentType.id"
        class="shrink "
      >
        <fund-document-editor
          :dq="'test'"
          :document-type="documentType"
          :value="activeBond.reports[documentType.name]"
          @input="$set(activeBond.reports, documentType.name, $event)"
          @invalidateFile="getBond(activeBond.dq)"
        ></fund-document-editor>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
  import Toolbar
    from "@/components/common/Toolbar"
  import {
    mapActions,
  } from "vuex"
  import FundDocumentEditor
    from "@/components/funds/FundDocumentEditor"

  export default {
    name: "Bonds",
    components: {
      FundDocumentEditor,
      Toolbar,
    },
    methods: {
      ...mapActions(['fetchBond', 'updateBond', 'showSnackbar']),
      async getBond(dq) {
        this.loading = true

        try {
          const response = await this.fetchBond(dq)
          if (response) {
            this.activeBond = response
            this.loading = false
          }
        } catch {

          this.showSnackbar({
            text: this.$t('bonds.searchError'),
            type: 'error',
          })
          this.loading = false
        }
      },
      setDate(type, input) {
        this.activeBond.subscriptionInfo[type] = new Date(input).toISOString()
      },
      async saveBond() {
        this.loading = true

        const result = this.updateBond(this.activeBond)
        if (result) {
          this.showSnackbar({
            text: this.$t('bonds.saved'),
          })
        } else {
          this.showSnackbar({
            text: this.$t('bonds.saveError'),
            type: 'error',
          })
        }
        this.loading = false

      },
    },
    data() {
      return {
        search: null,
        loading:false,
        activeBond: null,
        documentTypes: [
          {
            name: 'kid',
            id: 'KID',
          },
          {
            name: 'beiblatt',
            id: 'BEIBLATT',
          },
        ],
      }
    },
  }
</script>
