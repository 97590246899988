<template>
  <v-card
    rounded
    outlined
  >
    <v-card-title>
      {{ $t('funds.reports.' + documentType.name) }}
      <v-spacer></v-spacer>
      <v-btn
        v-if="value"
        :href="value.url"
        target="_blank"
        icon
        plain
        class="ml-1"
      >
        <v-icon
          color="accent"
        >
          open_in_new
        </v-icon>
      </v-btn>
      <v-icon
        :color="value ? (isRemovable ? 'success' : 'warning') : 'red'"
        right
      >
        {{ value ? 'check' : 'clear' }}
      </v-icon>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col
          v-if="value !== null"
          cols="12"
        >
          {{ $t('funds.reports.reportDate') }} {{ value.date | localDate }}.

          {{ isRemovable ? $t('funds.reports.isCustomUpload') : $t('funds.reports.isBasicUpload') }}
        </v-col>

        <v-col
          v-if="value !== null"
        >
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-file-input
            v-model="upload"
            :label="$t('funds.reports.uploadNewFile')"
            truncate-length="20"
            rounded
            hide-details
            accept=".pdf"
            outlined
            dense
            @click:append="uploadProgress = null"
          ></v-file-input>
        </v-col>
        <v-col
          v-if="upload !== null"
          cols="12"
        >
          <v-menu
            v-model="datePickerMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template
              v-slot:activator="{ on, attrs }"
            >
              <v-text-field
                v-model="reportDate"
                :label="$t('funds.reports.selectDate')"
                prepend-icon="today"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reportDate"
              no-title
              :locale="$vuetify.lang.current"
              @input="datePickerMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          v-if="reportDate !== null && upload !== null"
          cols="12"
        >
          <v-btn
            width="100%"
            color="primary"
            @click="saveFile"
          >
            <v-icon left>
              file_upload
            </v-icon>
            {{ $t('actions.upload') }}
          </v-btn>
        </v-col>
        <v-col
          v-if="(value !== null) && isRemovable"
          cols="12"
        >
          <v-btn
            :disabled="!isRemovable"
            plain
            class="px-2"
            @click="removeFile"
          >
            <v-icon
              left
              color="error"
            >
              delete_forever
            </v-icon>
            {{ $t('funds.reports.removeFile') }}
          </v-btn>
        </v-col>
        <v-col
          v-if="uploadProgress"
          cols="12"
        >
          <v-progress-linear
            class="mt-2"
            rounded
            indeterminate
            color="primary lighten-1"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
  import { mapActions } from "vuex"

  export default {
    name: 'FundDocumentEditor',
    components: {},
    props: {
      documentType: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: false,
        default: null,
      },
      dq: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        datePickerMenu: null,
        upload: null,
        uploadProgress: false,
        reportDate: null,
      }
    },
    computed: {
      isRemovable() {
        return this.value.isRemovable
      },

    },
    methods: {
      ...mapActions(['uploadFile', 'showSnackbar', 'invalidateFile']),
      async removeFile() {
        let result = await this.invalidateFile({
          dq: this.dq,
          type: this.documentType.id,
        })

        if (result) {
          this.$emit('invalidateFile')
          this.showSnackbar({
            text: this.$t('funds.reports.invalidateSuccess', [this.$t(`funds.reports.${this.documentType.name}` )]),
          })
        }

      },
      async saveFile() {
        this.uploadProgress = true
        console.log(this.upload)

        let result

        try{
          result = await this.uploadFile({
            dq: this.dq,
            file: this.upload,
            reportDate: this.reportDate,
            type: this.documentType.id,
          })
          this.$emit('input', {
            dq: this.dq,
            type: this.type,
            url: result.data.data.link,
            date: result.data.data.reportDate,
            isRemovable: result.data.data.isRemovable,
          })
          this.showSnackbar({text: this.$t('funds.reports.uploadSuccess', [this.upload.name])})

          this.upload = null
          this.reportDate = null
        } catch (e) {
          this.showSnackbar({text: this.$t('funds.reports.uploadError', [this.upload.name]), type: 'error'})
        }

        this.uploadProgress = false
      },
    },
  }
</script>
